import React, { Component } from "react";
import {
  Button,
  Collapse,
  createStyles,
  StepConnector,
  Stepper,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import ClickableCard from "./ClickableCard";
import IconApi from "../../../images/icons/icon-api.svg";
import IconActivation from "../../../images/icons/icon-activation.svg";
import IconDocumentation from "../../../images/icons/icon-documentation.svg";
import pageStepper from "../../../images/icon-arcadia-white.png";
import CloseIcon from "@material-ui/icons/Cancel";
import { Step } from "./Steps";
import { isBrowser } from "../../auth/SecurityContext";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      padding: `0 ${theme.spacing(3)}px`
    },
    title: {
      fontSize: 30,
      fontWeight: 100
    },
    links: {
      display: "flex",
      marginTop: theme.spacing(1),
      "&>*": {
        marginRight: theme.spacing(3),
        flexGrow: 1,

        "&:last-child": {
          marginRight: 0
        }
      }
    },
    arcadiaLogo: {
      width: 48
    },
    step: {},
    stepLabel: {
      color: "black !important"
    },
    stepLabelInactive: {
      color: "#D5D5D5"
    },
    closeButton: {
      color: theme.palette.grey.A100,
      fontWeight: "bold",
      "&:hover": {
        background: theme.palette.grey[50]
      }
    },
    closeButtonIcon: {
      marginLeft: theme.spacing(1)
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    },
    connectorActive: {
      "& $connectorLine": {
        borderColor: theme.palette.common.black
      }
    },
    connectorLine: {}
  });

const LOCAL_STORAGE_STATE_KEY = "onboarding.open";
const sidebarDefaultState = () =>
  isBrowser()
    ? localStorage.getItem(LOCAL_STORAGE_STATE_KEY) !== "false"
    : true;
const persistSidebarState = (state: boolean) => {
  if (isBrowser()) {
    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, `${state}`);
  }
};

interface Props extends WithStyles<typeof styles> {}

export const onboardingVisibleQuery = gql`
  query OnboardingVisible {
    onboarding @client {
      onboardingStepperVisible
    }
  }
`;

const mutation = gql`
  mutation CloseOnboarding {
    closeOnboardingStepper @client {
      onboardingStepperVisible
    }
  }
`;

export class PageStepper extends Component<Props> {
  render() {
    const { classes } = this.props;

    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          line: classes.connectorLine
        }}
      />
    );

    return (
      <Query query={onboardingVisibleQuery} fetchPolicy="cache-first">
        {({ data }) => (
          <Collapse
            className={classes.root}
            in={
              data &&
              data.onboarding &&
              data.onboarding.onboardingStepperVisible
            }
          >
            <div className={classes.header}>
              <Typography className={classes.title}>Getting Started</Typography>

              {this.closeButton()}
            </div>

            <div>
              <Stepper alternativeLabel activeStep={2} connector={connector}>
                <Step label="Register Account" />
                <Step label="Generate API Keys" />
                <Step label="Prototype Fund Experience" />
                <Step label="Design Fund Product" />
                <Step label="Activate Account" />
                <Step label="Launch Your Fund" />
              </Stepper>
            </div>

            <div className={classes.links}>
              <ClickableCard
                title="Documentation"
                description="Read the basics on how to get started with our API."
                image={IconDocumentation}
                to="/docs/"
              />

              <ClickableCard
                title="API Reference"
                description="Get to know all the resources available on our API."
                image={IconApi}
                to="/api/"
              />

              <ClickableCard
                title="Production Access"
                description="Enquire now to get access to production features."
                image={IconActivation}
                to="/apply"
              />

              <ClickableCard
                title="Try Arcadia"
                description="Learn about how to efficiently manage compliance."
                image={({ className }) => (
                  <img src={pageStepper} className={className} />
                )}
                to="https://www.sargon.com/products/arcadia/"
                external
              />
            </div>
          </Collapse>
        )}
      </Query>
    );
  }

  closeButton = () => (
    <Mutation mutation={mutation}>
      {close => (
        <Button
          className={this.props.classes.closeButton}
          onClick={() => close()}
        >
          Hide
          <CloseIcon
            className={this.props.classes.closeButtonIcon}
            color="inherit"
          />
        </Button>
      )}
    </Mutation>
  );
}

export default withStyles(styles)(PageStepper);
